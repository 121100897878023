import { Directive, Input, ElementRef } from '@angular/core';

@Directive({
    selector: '[imageHighDpi]',
})
export class AssetsImageDirective {
    private readonly folder = 'https://app-acceptance.sitly.com/releases/20240708112723/assets/images/';

    @Input() set imageHighDpi({ imageName, extension = 'png' }: { imageName: string; extension?: string }) {
        if (!imageName) {
            return;
        }

        const src = `${this.folder}${imageName}.${extension}`;
        const src2x = `${this.folder}${imageName}@2x.${extension} 2x`;
        const src3x = `${this.folder}${imageName}@3x.${extension} 3x`;
        this.element.nativeElement.src = src;
        this.element.nativeElement.srcset = `${src2x}, ${src3x}`;

        // handle missing icon in 2x or 3x resolution
        this.element.nativeElement.onerror = () => {
            this.element.nativeElement.srcset = src2x;
            this.element.nativeElement.onerror = () => {
                this.element.nativeElement.srcset = src;
                this.element.nativeElement.onerror = () => {
                    console.log('image missed', imageName);
                };
            };
        };
    }

    constructor(private element: ElementRef<HTMLImageElement>) {}
}
