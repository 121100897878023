import { Injectable, EventEmitter } from '@angular/core';
import { ShareMethod } from 'app/models/api/country-settings-interface';
import { PhotoUploadPurpose } from 'app/models/api/photo';
import { StorageService } from 'app/services/storage.service';

export enum AppEventType {
    recommendationMessageSent = 'recommendationMessageSent',
    feedbackSent = 'feedbackSent',
    facebookPhotoRequest = 'facebookPhotoRequest',
    instagramPhotoRequest = 'instagramPhotoRequest',
    webPhotoSelected = 'webPhotoSelected',
    galleryPhotoSelected = 'galleryPhotoSelected',
    forceUploadPhoto = 'forceUploadPhoto',
    paymentComplete = 'paymentComplete',
    reportPhoto = 'reportPhoto',
    initialOverlayClosed = 'initialOverlayClosed',
    searchList = 'searchList',
    messagesTab = 'messagesTab',
    checkPrompts = 'checkPrompts',
}

export type AppEvent =
    | {
          type: AppEventType.recommendationMessageSent;
          data: RecommendationSentNotification;
      }
    | {
          type: AppEventType.reportPhoto;
          data: { userId: string };
      }
    | {
          type:
              | AppEventType.feedbackSent
              | AppEventType.paymentComplete
              | AppEventType.initialOverlayClosed
              | AppEventType.searchList
              | AppEventType.messagesTab
              | AppEventType.checkPrompts;
      };

export interface RecommendationSentNotification {
    name: string;
    shareMethod: ShareMethod;
}

export interface WebPhotoSelectedNotification {
    url: string;
    purpose: PhotoUploadPurpose;
}

export interface GalleryPhotoSelectedNotification {
    imgData: string;
    purpose: PhotoUploadPurpose;
}

@Injectable({
    providedIn: 'root',
})
export class AppEventService {
    events = new EventEmitter<AppEvent>();

    constructor(private storageService: StorageService) {}

    notifyRecommendationSent(data: RecommendationSentNotification) {
        this.events.emit({ type: AppEventType.recommendationMessageSent, data });
    }

    notifyFeedbackSent() {
        this.events.emit({ type: AppEventType.feedbackSent });
    }

    notifyPaymentComplete() {
        this.events.emit({ type: AppEventType.paymentComplete });
    }

    notifyReportPhoto(userId: string) {
        this.events.emit({ type: AppEventType.reportPhoto, data: { userId } });
    }

    notifyInitialOverlayClosed() {
        this.events.emit({ type: AppEventType.initialOverlayClosed });
    }

    notifySearchListLoad() {
        this.events.emit({ type: AppEventType.searchList });
    }

    notifyMessagesTabLoad() {
        this.events.emit({ type: AppEventType.messagesTab });
    }

    fireEventAfterRefresh(event: AppEvent) {
        this.storageService.pushToEventQueue(event);
    }

    processPostRefreshEvents() {
        this.storageService.eventQueue.forEach(event => this.events.emit(event));
        this.storageService.eventQueue = [];
    }

    sendPromptCheckEvent() {
        this.events.emit({ type: AppEventType.checkPrompts });
    }
}
