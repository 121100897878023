import { Component, computed, input } from '@angular/core';
import { OverlayContentData } from 'app/components/common/overlay-content/types';

@Component({
    selector: 'overlay-template',
    templateUrl: './overlay-template.component.html',
    styleUrls: ['./overlay-template.component.less'],
})
export default class OverlayTemplateComponent {
    readonly data = input.required<OverlayContentData>();

    readonly hasButtons = computed(
        () => this.data().linkBtn || this.data().primaryBtn || this.data().secondaryBtn || this.data().dangerBtn,
    );

    readonly hasManyButtons = computed(
        () => [this.data().primaryBtn, this.data().secondaryBtn, this.data().dangerBtn].reduce((acc, item) => acc + (item ? 1 : 0), 0) > 1,
    );

    onHtmlClick(event: Event) {
        if (event.target instanceof HTMLAnchorElement) {
            if (new URL(event.target.href).host !== window.location.host) {
                event.stopPropagation();
                event.preventDefault();

                window.open(event.target.href, '_blank');
            }
        }
    }
}
