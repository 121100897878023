<div class="content-container" [class.bg-neutral]="data().bgColor === 'neutral'" [class.full-screen]="data().fullScreen">
    <button
        class="btn-overlay-close"
        (click)="data().doOnClose?.()"
        [trackLabel]="{ category: data().trackCategory, type: 'overlay', description: 'close-' + data().trackName }"
    ></button>

    @if (data().img || data().title) {
        <div class="top-bar">
            @if (data().img?.type === 'svg') {
                <img class="header-img" src="https://app-acceptance.sitly.com/releases/20240708112723/assets/images/{{ data().img?.name }}.svg" />
            }
            @if (data().img?.type === 'png') {
                <img class="header-img" [imageHighDpi]="{ imageName: data().img?.name ?? '', extension: 'png' }" />
            }

            @if (data().title) {
                <div
                    class="title"
                    [class.text-align-left]="data().titleAlignLeft"
                    innerHTML="{{ data().title ?? '' | translate: data().titleArgs }}"
                ></div>
            }
        </div>
    }

    <div class="content" [class.text-align-left]="data().textAlignLeft">
        @if (data().message) {
            <div class="message" innerText="{{ data().message ?? '' | translate: data().messageArgs }}"></div>
        }

        @if (data().htmlMessage) {
            <div class="html-message" innerHTML="{{ data().htmlMessage ?? '' | translate }}" (click)="onHtmlClick($event)"></div>
        }

        <ng-content></ng-content>
    </div>

    @if (hasButtons()) {
        <div class="bottom-bar">
            <div class="buttons-container">
                @if (data().dangerBtn) {
                    <system-button
                        [buttonType]="'danger'"
                        (click)="data().dangerBtn?.action?.()"
                        [class.stretch]="hasManyButtons()"
                        [iconRight]="data().dangerBtn?.iconRight"
                        [trackLabel]="{ category: data().trackCategory, type: 'overlay', description: 'danger-' + data().trackName }"
                    >
                        {{ data().dangerBtn?.title ?? '' | translate }}
                    </system-button>
                }
                @if (data().primaryBtn) {
                    <system-button
                        [buttonType]="'primary'"
                        (click)="data().primaryBtn?.action?.()"
                        [class.stretch]="hasManyButtons()"
                        [iconRight]="data().primaryBtn?.iconRight"
                        [trackLabel]="{ category: data().trackCategory, type: 'overlay', description: 'primary-' + data().trackName }"
                    >
                        {{ data().primaryBtn?.title ?? '' | translate }}
                    </system-button>
                }
                @if (data().secondaryBtn) {
                    <system-button
                        [buttonType]="'secondary'"
                        (click)="data().secondaryBtn?.action?.()"
                        [class.stretch]="hasManyButtons()"
                        [iconRight]="data().secondaryBtn?.iconRight"
                        [trackLabel]="{ category: data().trackCategory, type: 'overlay', description: 'secondary-' + data().trackName }"
                    >
                        {{ data().secondaryBtn?.title ?? '' | translate }}
                    </system-button>
                }
            </div>
            @if (data().linkBtn) {
                <button
                    class="btn-underlined-new neutral-700"
                    (click)="data().linkBtn?.action?.()"
                    [trackLabel]="{ category: data().trackCategory, type: 'button', description: 'link-' + data().trackName }"
                >
                    {{ data().linkBtn?.title ?? '' | translate }}
                </button>
            }
        </div>
    }

    @if (data().stickyBtn) {
        <div class="sticky-button-container">
            <system-button [buttonType]="'primary'" (click)="data().stickyBtn?.action?.()">
                {{ data().stickyBtn?.title ?? '' | translate }}
            </system-button>
        </div>
    }
</div>
