import { enableProdMode, ErrorHandler, isDevMode, importProvidersFrom } from '@angular/core';
import { environment } from 'environments/environment';
import { AppComponent } from 'app/components/app/app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { WebAppTranslateService } from 'app/services/web-app-translate.service';
import { translateModuleConfig } from 'app/services/translation.service';
import { TranslateModule } from '@ngx-translate/core';
import { DateFnsModule } from 'ngx-date-fns';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from 'routing/routes';
import { SharedModule } from 'modules/shared/shared.module';
import { ApiInterceptor } from 'app/services/api/api.service';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { CacheRouteReuseStrategy } from 'routing/route-reuse-strategy';
import { RouteReuseStrategy } from '@angular/router';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { SentryErrorHandler, browserTracingIntegration, init } from '@sentry/angular';
import { EnvironmentUtils } from 'app/utils/device-utils';
import { DataLayerDimensions } from 'app/services/tracking/types';
import { OverlayTestService } from 'app/services/overlay/overlay-test.service';

declare global {
    export interface Window {
        __sitly_enhanced_convestion_tracking: {
            email: string | undefined;
            address: {
                first_name: string | undefined;
                last_name: string | undefined;
                street: string | undefined;
                city: string | undefined;
                country: string | undefined;
            };
        };
        dataLayer: Partial<DataLayerDimensions>[];
        ga?: {
            (...args: unknown[]): void;
            q?: unknown[];
            l?: unknown;
        };
        Cypress?: unknown;
        overlayTestService: OverlayTestService;
    }
}

declare global {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface Array<T> {
        aggregatedDescription(lastSeparator?: string): string;
    }
}

Array.prototype.aggregatedDescription = function (lastSeparator = ' & ') {
    if (this.length === 0) {
        return '';
    }
    return this.reduce((previous: string, current, index, array) => {
        if (index === 0) {
            return `${current}`;
        }
        return index === array.length - 1 ? `${previous}${lastSeparator}${current}` : `${previous}, ${current}`;
    }, '');
};

init({
    dsn: 'https://1802fa6881e640838fb7370aa739c7ec@sentry.io/120366',
    ignoreErrors: [
        'top.GLOBALS', // Random plugins/extensions
        'Http failure response', // non 2xx http responses
        'Non-Error exception captured with keys',
        'AbortError: The operation was aborted',
        'Handled unknown error',
    ],
    integrations: [browserTracingIntegration()],
    allowUrls: [/sitly/i, /2care4kids/i],
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-var-requires
    release: `wb@${require('../package.json').version}`,
    environment: environment.name,
    tracesSampleRate: 0.0035,
    beforeSend(event) {
        event.tags = event.tags ?? {};
        event.tags.countryCode = (localStorage.countryCode ?? 'unknown') as string;
        return event;
    },
});

class LocalErrorHandler extends SentryErrorHandler {
    handleError(error: Error): void {
        if (/Loading chunk [\d]+ failed/.test(error.message)) {
            window.location.reload();
        } else {
            super.handleError(error);
        }
    }
}

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            SharedModule,
            AppRoutingModule,
            BrowserModule,
            MatDialogModule,
            MatBottomSheetModule,
            ReactiveFormsModule,
            FormsModule,
            DateFnsModule.forRoot(),
            // TODO: remove this after finish migration to new app module
            TranslateModule.forRoot(translateModuleConfig(WebAppTranslateService, false)),
            ServiceWorkerModule.register('ngsw-worker.js', {
                enabled: !isDevMode(),
                registrationStrategy: 'registerWhenStable:30000',
            }),
        ),
        ...(EnvironmentUtils.isLocalhost
            ? []
            : [
                  {
                      provide: ErrorHandler,
                      useValue: new LocalErrorHandler({
                          showDialog: false,
                      }),
                  },
              ]),
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                backdropClass: 'dialog-backdrop',
                panelClass: 'dialog-panel',
            },
        },
        {
            provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                backdropClass: 'dialog-backdrop',
                panelClass: 'bottom-sheet-panel',
            },
        },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: RouteReuseStrategy, useClass: CacheRouteReuseStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
    ],
});
