import { StorageService } from 'app/services/storage.service';
import { map } from 'rxjs/operators';
import { CountrySettings } from 'app/models/api/country-settings-interface';
import { ApiService } from 'app/services/api/api.service';
import { Injectable, EventEmitter } from '@angular/core';
import { ServerResponseData } from 'app/models/api/response';

@Injectable({
    providedIn: 'root',
})
export class CountrySettingsService {
    readonly changed = new EventEmitter<CountrySettings>();
    get defaultLocale() {
        // TODO: new auth - maybe try to get locale from browser
        return this.countrySettings?.defaultLocale ?? 'en-GB';
    }
    countrySettings = this.storageService.countrySettings;

    constructor(
        private apiService: ApiService,
        private storageService: StorageService,
    ) {}

    private updateIfNecessary(newConfig?: CountrySettings) {
        if (newConfig && JSON.stringify(this.countrySettings) !== JSON.stringify(newConfig)) {
            this.storageService.countrySettings = newConfig;
            this.countrySettings = newConfig;
            this.changed.emit(newConfig);
        }
    }

    refreshCountrySettings() {
        return this.apiService.get('/country-settings').pipe(
            map(response => {
                const data = response.data as ServerResponseData[];
                const newConfig = data?.reduce((accumulator, current) => {
                    accumulator[current?.attributes?.id as keyof CountrySettings] = current?.attributes?.value as never;
                    return accumulator;
                }, {} as CountrySettings);
                this.updateIfNecessary(newConfig);
                return this.countrySettings;
            }),
        );
    }
}
